<template>
  <div id="school-standards">
      <b-container class="base-container-x">
        <div v-if="loading === true" class="col-12">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </div>
        <div v-if="loading === false" id="banner" class="banner-inner oycp">
          <!-- <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h1 class="section-header-title text-uppercase">{{ $t('EYEP') }}</h1>
                <h3 class="article-content">{{ $t('Exchange Yoga Experience Program (WYA Continuous Yoga Education)') }}</h3>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="loading === true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
        <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
          <b-row>
            <!-- <b-col cols="12" md="3" lg="3" class="d-flex justify-content-center align-items-center">
              <b-img class="d-none d-md-block w-100" :src="PUBLIC_PATH+'img/certificate/OYCP.png'"/>
              <b-img class="d-flex d-md-none w-50 mb-5" :src="PUBLIC_PATH+'img/certificate/OYCP.png'"/>
            </b-col> -->
            <b-col cols="12">
              <div class="d-flex">
                  <div class="vl mr-4"></div>
                  <h5>{{ $t('Bring your business to the next level. Get your online yoga training accredited with WYA. ') }}</h5>
              </div>
              <div class="mt-3">
                <p>{{ $t('WYA strive to support and empower our yoga school members to raise the Yoga Educational Standards with an Accredited Online Yoga Teacher Trainings.') }}</p>
                <p>{{ $t('We believe that effective yoga teacher training can be done not only by teaching face to face but we also see the possibilities in online teaching with the same high-quality of educational standards.') }}</p>
                <p>{{ $t('Please find the information for School Registration as an ” Online Yoga Course Provider (OYCP) ” below.') }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-img class="w-100" :src="require('../../../assets/images/OYCP/OYCP_1.jpg')" />
            </b-col>
            <b-col cols="12" md="6" class="mt-5 mt-sm-0">
              <b-img class="w-100" :src="require('../../../assets/images/OYCP/OYCP_2.jpg')" />
            </b-col>
          </b-row>
          <h3 class="text-uppercase mt-5">{{ $t('Educational Standards') }}</h3>
          <b-row class="mt-3">
            <b-col cols="12" md="6">
              <div class="mt-3">
                <h5>{{ $t('50 Hours training level:') }}</h5>
                <p><strong>{{ $t('Duration') }}:</strong> {{ $t('1 Month Minimum and 3 Months Maximum. However, it depends on the Schools or course timetable please check with school.') }}</p>
                <b-table-simple caption-top responsive bordered class="table-default mt-3">
                  <b-thead>
                    <b-tr>
                    <b-th variant="light" >{{ $t('Topics') }}</b-th>
                    <b-th variant="light" class="w-25">{{ $t('Total Hours') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                    <b-td >{{ $t('Pre-recorded VDO / Live Streaming Sessions: including both Theoretical and Practical parts') }}</b-td>
                    <b-td >{{ $t('24 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Assessment: including Discussion, Q&A session for each module to ensure students are engaged in learning with understanding') }}</b-td>
                    <b-td >{{ $t('16 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Non-Contact Hours: including Homework, Assignment and Self-study') }}</b-td>
                    <b-td >{{ $t('10 Hours') }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <p class="mb-0"><i>{{ $t('Total Contact Hours: 40 Hours') }}</i></p>
                <p><i>{{ $t('Total Non-contact Hours: 10 Hours') }}</i></p>
              </div>
              <div class="mt-5">
                <h5>{{ $t('100 Hours training level:') }}</h5>
                <p><strong>{{ $t('Duration') }}:</strong> {{ $t('2 Months Minimum and 4 Months Maximum. However, it depends on the Schools or course timetable please check with school.') }}</p>
                <b-table-simple caption-top responsive bordered class="table-default mt-3">
                  <b-thead>
                    <b-tr>
                    <b-th variant="light" >{{ $t('Topics') }}</b-th>
                    <b-th variant="light" class="w-25">{{ $t('Total Hours') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                    <b-td >{{ $t('Pre-recorded VDO / Live Streaming Sessions: including both Theoretical and Practical parts') }}</b-td>
                    <b-td >{{ $t('48 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Assessment: including Discussion, Q&A session for each module to ensure students are engaged in learning with understanding') }}</b-td>
                    <b-td >{{ $t('32 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Non-Contact Hours: including Homework, Assignment and Self-study') }}</b-td>
                    <b-td >{{ $t('20 Hours') }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                  <p class="mb-0"><i>{{ $t('Total Contact Hours: 80 Hours') }}</i></p>
                  <p><i>{{ $t('Total Non-contact Hours: 20 Hours') }}</i></p>
              </div>
            </b-col>
            <b-col cols="12" md="6" class="mt-3 mt-sm-0">
              <div class="mt-3">
                <h5>{{ $t('200 Hr. Level') }}</h5>
                <p><strong>{{ $t('Duration') }}:</strong> {{ $t('5 Months Minimum and 12 Months Maximum. However, it depends on the Schools or course timetable please check with school.') }}</p>
                <b-table-simple caption-top responsive bordered class="table-default mt-3">
                  <b-thead>
                    <b-tr>
                      <b-th variant="light" >{{ $t('Topics') }}</b-th>
                      <b-th variant="light" class="w-25">{{ $t('Total Hours') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                    <b-td >{{ $t('Pre-recorded VDO / Live Streaming Sessions: including both Theoretical and Practical parts') }}</b-td>
                    <b-td >{{ $t('96 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Assessment: including Discussion, Q&A session for each module to ensure students are engaged in learning with understanding') }}</b-td>
                    <b-td >{{ $t('64 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Non-Contact Hours: including Homework, Assignment and Self-study') }}</b-td>
                    <b-td >{{ $t('40 Hours') }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <p class="mb-0"><i>{{ $t('Total Contact Hours: 160 Hours') }}</i></p>
                <p><i>{{ $t('Total Non-contact Hours: 40 Hours') }}</i></p>
              </div>
              <div class="mt-5">
                <h5>{{ $t('300 Hours training level 2:') }}</h5>
                <p><strong>{{ $t('Duration') }}:</strong> {{ $t('6 Months Minimum and 12 Months Maximum. However, it depends on the Schools or course timetable please check with school.') }}</p>
                <b-table-simple caption-top responsive bordered class="table-default mt-3">
                  <b-thead>
                    <b-tr>
                      <b-th variant="light" >{{ $t('Topics') }}</b-th>
                      <b-th variant="light" class="w-25">{{ $t('Total Hours') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                    <b-td >{{ $t('Pre-recorded VDO / Live Streaming Sessions: including both Theoretical and Practical parts') }}</b-td>
                    <b-td >{{ $t('140 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Assessment: including Discussion, Q&A session for each module to ensure students are engaged in learning with understanding') }}</b-td>
                    <b-td >{{ $t('100 Hours') }}</b-td>
                    </b-tr>
                    <b-tr>
                    <b-td >{{ $t('Non-Contact Hours: including Homework, Assignment and Self-study') }}</b-td>
                    <b-td >{{ $t('60 Hours') }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <p class="mb-0"><i>{{ $t('Total Contact Hours: 240 Hours') }}</i></p>
                <p><i>{{ $t('Total Non-contact Hours: 60 Hours') }}</i></p>
              </div>
            </b-col>
          </b-row>
          <p class="mt-3"><strong>{{ $t('NOTE') }}:</strong> {{ $t('This is considered as WYA minimum standards for Online YTTC. Please make sure your curriculum meets our standard requirement before submitting your application. It is also important to maintain high standards of teaching at all times.') }}</p>
          <b-row class="mt-5">
            <b-col cols="12" md="6">
              <h3 class="text-uppercase">{{ $t('Documentation') }}</h3>
                <div class="mt-3">
                <h5 class="text-uppercase">{{ $t('What documents do I need to submit?') }}</h5>
                <p>{{ $t('The following documents are essential and must be submitted via application form or email') }} (<a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a>) {{ $t('Please be reminded that without these required documents your application may not be proceeding for the review.') }}</p>
                <ul>
                  <li>{{ $t('Curriculum Vitae (CV) for the Director or main teacher.') }}</li>
                  <li>{{ $t('Photocopies of main teacher’s yoga certificate of completion or other relevant diplomas.') }}</li>
                  <li>{{ $t('School information including School logo, School Introduction, Style of Yoga, Code of Ethics for YTTC.') }}</li>
                  <li>{{ $t('A copy of training syllabus for the yoga teacher training course(s), clearly indicating educational areas of study, as well as the training hours breakdown for each part.') }}</li>
                  <li>{{ $t('Teaching materials including VDO content / training manual.') }}</li>
                  <li>{{ $t('Written explanation of how you organise to teach, what are the teaching methods, how you manage to assign homework and follow-up with your students.') }}</li>
                  <li>{{ $t('You will be required to provide us a temporary access to your course just to let our committee review the content.') }}</li>
                </ul>
                <p><strong>{{ $t('Remark') }}:</strong></p>
                <p>{{ $t('Please rest assured that all documents submitted will be treated with confidentiality and will not be made accessible to third parties.') }}</p>
              </div>
            </b-col>
            <b-col cols="12" md="6" class="d-flex flex-column justify-content-center  align-items-center">
                <b-img class="w-100 mt-5" :src="require('../../../assets/images/OYCP/OnlineTraining.jpg')" />
                <b-img class="w-50 mt-5" :src="PUBLIC_PATH+'img/certificate/OYCP.png'"/>
            </b-col>
          </b-row>
          <div class="col text-center"><b-button pill :to="{ name: 'authenLogin' }" class="mt-5 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Register now') }}</b-button></div>
        </div>
      </b-container>
      <div class="section-bottom">
        <h3 class="text-uppercase">{{ $t('Already WYA School Member?') }}</h3>
        <h5>{{ $t('Need to upgrade your in-person training to an online training?') }}</h5>
        <p>{{ $t('No problem! If you have registered your school with us and already become WYA member, you can also transfer your onsite yoga teacher training course that has been registered with us to an online training. Please follow these few steps;') }}</p>
        <ol>
          <li>{{ $t('Send us an email with the details of your Online Training to') }} <a href="mailto:contact@wyayoga.org" target="_blank" title="Email WYA Yoga Office"><u>contact@wyayoga.org</u></a></li>
          <li>{{ $t('Provide us an access to your online course for the review.') }}</li>
          <li>{{ $t('Submit the upgrade') }} <router-link :to="{ name: 'feeDetails' }">{{ $t('fee') }}</router-link></li>
          <li>{{ $t('Get your school profile updated and permission to use OYCP logo.') }}</li>
          <li>{{ $t('For further assistance or queries, please feel free to') }}  <router-link :to="{ name: 'contact' }">{{ $t('contact') }}</router-link> {{  $t('our team members.')}}</li>
        </ol>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ChildrenYogaSchool',
  title: ' | Children\'s Yoga School',
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    scrollToElement (id) {
      const element = document.getElementById(id)
      const elementOffset = element.offsetTop - 80
      console.log('elementOffset', elementOffset)
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
 #school-standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .vl {
    border-left: 6px solid black;
    height: 2rem;
  }
  .section-bottom {
    background-color: #f3f3f3;
    padding-top: 5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 5rem;

  }
  @media screen and (max-width: 767.98px) {
    .section-bottom {
    background-color: #f3f3f3;
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px){
    .section-bottom {
      background-color: #f3f3f3;
      padding-top: 5rem;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-bottom: 5rem;
    }
  }
</style>
